<template>
	<div class="menu">
		<div class="nav">
      <div class="nav__item">
        <div @click="scrollToSection('home')" class="desc hover-link">Home</div>
      </div>
      <div class="nav__item">
        <div @click="scrollToSection('about')" class="desc hover-link">About Us</div>
      </div>
      <div class="nav__item">
        <div @click="scrollToSection('howToUse')" class="desc hover-link">How to use</div>
      </div>
      <div class="nav__item">
        <div @click="scrollToSection('categories')" class="desc hover-link">Categories</div>
      </div>
      <div class="nav__item">
        <div @click="$parent.openContactModal" class="desc">Contact</div>
      </div>
      <div class="nav__item">
        <router-link to="/product-list" class="desc">Marketplace</router-link>
      </div>
      <!--<div class="nav__item" @click="$parent.goToPromtCreate">
        <div class="desc">Sell</div>
      </div>-->
    </div>
    <div class="header-profile" v-if="$parent.isAuth">
      <div class="cart" @click="$parent.openBuyModal">
        <img class="img" src="./../images/cart.svg"/>
      </div>
      <router-link to="/profile/my-profile" class="avatar-container button">
        My profile
      </router-link>
    </div>
    <div v-else class="button modal-open" @click="$emit('openSignInModal')">
      <span>Sign In</span>
    </div>
    <div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
      <Select2 class="select2 single currency-select" v-model="curr"
        @select="selectCurr($event)"
        :placeholder="curr"
        :options="currOptions"
      />
    </div>
	</div>
</template>

<script>
import Select2 from 'vue3-select2-component';
export default {
	name: 'Menu',
  props: ['userBalance', 'avatar_path', 'currencyCode', 'currencyValue'],
  components: {
		Select2
	},
	data: function() {
		return {
      curr: ''
		}
	},
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
	methods: {
		openSignInModal() {
			this.$emit('openSignInModal')
		},
		setStableDiffusionModel() {
			let model = this.$parent.models.find((obj) => obj.id === 1)
			this.$emit('setActiveModel', model);
		},
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
	},
}
</script>
