<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" @scrollToSection="scrollToSection" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency" @setActiveModel="setActiveModel" :avatar_path="avatar_path" :userBalance="userBalance" @openSignInModal="openSignInModal"/>
        </transition>
        <div v-if="$route.name != 'SignIn' && $route.name != 'SignUp'" class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class='header-container'>
          <Header v-if="$route.name != 'SignIn' && $route.name != 'SignUp'" @scrollToSection="scrollToSection" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency" :avatar_path="avatar_path" :userBalance="userBalance"  @openSignInModal="openSignInModal" @setActiveModel="setActiveModel"/>
        </div>
        <router-view :categoryOptions="categoryOptions" @setError="setError" @registration="registration" @login="login" @forgotPass="forgotPass" @chooseCategory="chooseCategory" :currencyValue="currencyValue" :currencyOptions="currencyOptions" @getActiveModelProducts="getActiveModelProducts" @getProductPageContent="getProductPageContent" :modelOptions="modelOptions" @editPrompt="editPrompt" :promptHistory="promptHistory" :orderHistory="orderHistory" @formatDate="formatDate"  :currency="currency"  @goToProduct="goToProduct" @setSort="setSort" @setActiveModel="setActiveModel" @setActiveCategory="setActiveCategory" :activeModels="activeModels" :activeCategories="activeCategories" :productPageItem="productPageItem" :models="models" @openBuyModal="openBuyModal" @setActiveSlider="setActiveSlider"/>
        <div class='footer-container'>
          <Footer @scrollToSection="scrollToSection" v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')" @setActiveModel="setActiveModel"/>
        </div>
        <transition name="fade">
          <ContactModal v-if="contactModalIsVisible" @closeContactModal="closeContactModal" @contactSubmit="contactSubmit" />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible" @withdrawSubmit="withdrawSubmit" @topUpSubmit="topUpSubmit"/>
        </transition>
        <transition name="fade">
          <BuyModal v-if="buyModalVisible" :currencyValue="currencyValue" @removeFromCart="removeFromCart" :cartContents="cartContents" :totalPrice="totalSum"/>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import {loadScriptsFromString} from "@/utils/load_scripts";
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import ContactModal from './components/ContactModal.vue'
import TopUpModal from './components/TopUpModal.vue'
import BuyModal from './components/BuyModal.vue' 
import {scroller} from 'vue-scrollto/src/scrollTo'


export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    ContactModal,
    TopUpModal,
    BuyModal
  },
  data: function() {
    return {
     pageLoaded: false,
     menuVisible: false,
     contactModalIsVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      promptHistory: [],
      depositHistory: [],
      createdHistory: [],
      stableDiffusion: [],
      midjourney: [],
      dalle2: [],
      chatGpt: [],
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: null,
      imgDomain: '',
      textPageList: '',
      hostname: '',
      models: [],
      categories: [],
      categoryOptions: [],
      categoryCreateOptions: [],
      modelOptions: [],
      packs: [],
      productList: [],
      sort: '',
      activeCategories: [],
      activeCategory: '',
      activeModels: [],
      dropdownCategories: [],
     topUpModalVisible: false,
     readyPromt: false,
     readyPack: false,
     buyModalVisible: false,
     isPack: false,
     userBalance: '0.00',
     avatar_path: '',
     cartContents: [],
     editData: null,
     activeModelProducts: [],
     currency: '',
     currencyValue: '',
     currencyCode: '',
     currencyOptions: [],
     activeSlider: null,
     topUpAction: '',
     profileData: '',
     topUpModalAction: false,
     settings: '',
     contactLoader: false
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      this.clearError();
      if (this.isAuth) {
        this.getPromptHistory();
        this.getOrderHistory();
        this.getDepositHistory();
        this.getCreatedHistory();
      }
    },
  },
  created() {
    let self = this;
    window.addEventListener('click', (e) => {
      const dropdown = document.querySelector(".dropdown");
      const categoryList = document.querySelector(".category-list");
      if (dropdown && categoryList) {
        if (!dropdown.contains(e.target) && !categoryList.contains(e.target)) {
          self.clearDropdownCategories();
        }
      }
    })
  },
  methods: {
    setError(error) {
      this.error = error;
    },
    chooseCategory(item) {
      this.activeCategory = item;
      this.$router.push({path: '/product-list'});
    },
    scrollToSection(section) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      } else {
        this.closeMenu();
      }

      var options = {
          offset: -100
      }
      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo('#' + section, 1000, options)
      }, 1000)
    },
    crealCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
      .then(() => {
        this.getCartContents();
        this.totalSum = 0;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    removeFromCart(item) {
      let param = {
        'id' : item.item_id,
        'product_type': item.item.product_type
      } 
      this.$http.post(process.env.VUE_APP_API + 'cart/remove', param)
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    addToCart(item, type) {
      let param = {
        'id': item.id,
        'count': 1,
        'product_type' : type
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', param)
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    goToPromtCreate() {
      if (this.isAuth == true) {
        this.$router.push({path: '/promt-create/new'});
      } else {
        this.openSignInModal();
      }
    },
    buyPack() {
      let data = {
        'pack_id': ''
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/pack', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.$router.push({path: '/profile/my-profile'});
          

        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    withdrawSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'withdraw/request', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          self.successMessage = res.data.message;
          setTimeout(function() {
            self.successMessage = "";
            self.closeTopUpModal();
            self.getProfileData();
          }, 3000)
        } else {
          this.error = res.data.message;
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
            let firstKey = Object.keys(res.response.data.errors)[0];
            if (typeof res.response.data.errors[firstKey] !== 'undefined') {
                this.error = res.response.data.errors[firstKey][0]
            } else {
                this.error = res.response.data.errors[0]
            }
        } else {
          this.error = res.response.data.message
        }
        console.log(res)
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    topUpSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'deposit/init', data)
      .then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          this.error = res.response.data.errors[0]
        } else {
          this.error = res.response.data.message;
        }
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
     buyProduct() {
      this.$http.post(process.env.VUE_APP_API + 'cart/purchase')
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.getCartContents();
          this.getProfileData();
          this.closeBuyModal();
          this.$router.push({path: '/profile/my-profile'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    openBuyModal(item, type) {
      if (this.isAuth) {
        if (item) {
          this.addToCart(item, type);
        }
        this.buyModalVisible = true;
      } else {
        this.openSignInModal();
      }
      
    },
    closeBuyModal() {
      this.buyModalVisible = false;
    },
    setReadyPromt() {
      this.readyPromt = true;
    },
    setReadyPack() {
      this.readyPack = true;
    },
    openTopUpModal(action) {
      this.topUpModalAction = action;
      this.topUpModalVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalVisible = false;
    },
    clearDropdownCategories() {
      this.dropdownCategories = [];
    },
    openModelDropdown(model) {
      this.dropdownCategories = this.categories.filter(item => item.parent_id === model.id);
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.clearError();
      this.$router.push({ path: '/sign-in' })
    },
    clearError() {
      this.error = '';
    },
    closeSignInModal() {
      this.$router.push({ path: '/' })
      this.clearError();
    },
    openContactModal() {
      this.contactModalIsVisible = true;
    },
    closeContactModal() {
      this.contactModalIsVisible = false;
      this.clearError();
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.$router.push({ path: '/profile/my-profile' })
          this.getPromptHistory();
          this.getOrderHistory();
          this.getDepositHistory();
          this.getCreatedHistory();
          this.getProfileData()
          this.getCartContents();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    contactSubmit(data) {
      let self = this;
      this.contactLoader = true;
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          self.successMessage = 'Thanks!'
          setTimeout(()=> {
            self.successMessage = '';
            self.closeContactModal();
          }, 3000)
        } else {
          console.log(res.response)
          this.error = res.response.data.message
        }
        this.contactLoader = false;
      })
      .catch((res) => {
        this.contactLoader = false;
        console.log(res)
          this.error = res.response.data.message;
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            this.getProfileData()
            this.$router.push({ path: '/profile/my-profile' })
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getPromptHistory() {
      this.$http.get(process.env.VUE_APP_API + 'user/prompts/purchased')
      .then((res) => {
        this.promptHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getCreatedHistory() {
      this.$http.get(process.env.VUE_APP_API + 'user/prompts/created')
      .then((res) => {
        this.createdHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.userBalance = res.data.balance;
        this.avatar_path = res.data.avatar_path;
        this.profileData = res.data;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      /*function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }*/
      // let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      // let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;
      let format = day + "." + month + "." + year;

      return format
    },
    getStableDiffusion() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=1&limit=8')
      .then((res) => {
        this.stableDiffusion = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getMidjourney() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=2&limit=8')
      .then((res) => {
        this.midjourney = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getDalle2() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=4&limit=8')
      .then((res) => {
        this.dalle2 = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getChatGpt() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=3&limit=8')
      .then((res) => {
        this.chatGpt = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    setActiveSlider(id) {
      this.activeSlider = id;
    },
    getActiveModelProducts(id) {
      this.setActiveSlider(0);
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=' + id + '&limit=8')
      .then((res) => {
        this.activeModelProducts = res.data.payload;
        this.setActiveSlider(id);
      })
      .catch(() => {
        
      })
    },
    clearModel() {
      this.activeModels = [];
    },
    clearCategory() {
      this.activeCategories = [];
    },
    goToProduct(item) {
      if (item.type == 'pack') {
        this.$router.push({path: '/products/pack' + item.id, params: {data: item.id}});
      } else {
        this.$router.push({path: '/products/prompt' + item.id, params: {data: item.id}});
      }
      this.getProductPageContent(item);
    },
    isObject(variable) {
      return typeof variable === 'object' && variable !== null;
    },
    getProductPageContent(item, type) {
      this.productPageItem = '';
      
      if (this.isObject(item)) {
        if (item.type == 'pack') {
          this.$http.get(process.env.VUE_APP_API + 'packs')
          .then((res) => {
            const packs = res.data.payload;
            const pack = packs.find((p) => p.id === item.id);
            this.productPageItem = pack;
          })
          .catch((res) => {
            console.log(res)
          })
        } else if (item.type == 'prompt') {
          this.$http.get(process.env.VUE_APP_API + 'products/' + item.id + '/info')
          .then((res) => {
            this.productPageItem = res.data.payload;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal();
            }
          })
        }
      } else {
        if (type == 'pack1'.replace(/[^A-Za-z]+/g, ' ')) {
          this.$http.get(process.env.VUE_APP_API + 'packs')
          .then((res) => {
            const packs = res.data.payload;
            const pack = packs.find((pa) => pa.id == item);
            this.productPageItem = pack;
          })
          .catch((res) => {
            console.log(res)
          })
        } else if (type == 'prompt1'.replace(/[^A-Za-z]+/g, ' ')) {
          this.$http.get(process.env.VUE_APP_API + 'products/' + item + '/info')
          .then((res) => {
            this.productPageItem = res.data.payload;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal();
            }
          })
        }
      }
      
      
    },
    goToPrivacy() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);  
        } 
      }
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item, params: {data: item}})
      this.getTextPageContent(item);
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
         
      })
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload.filter(item => item.parent_id !== null);
        const newCategories = this.categories.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        // newCategories.unshift({name: 'All'});
        const filteredArray = this.removeIdenticalObjects(newCategories);
        this.categoryOptions = filteredArray;
        this.categoryCreateOptions = newCategories;
        this.activeCategoies = [];
      })
      .catch(() => {
         
      })
    },
    removeIdenticalObjects(arr) {
      return arr.filter((item, index) => {
        const firstIndex = arr.findIndex((el) => el.name === item.name);
        return index === firstIndex;
      });
    },
    getModels() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.models = res.data.payload.filter(item => item.parent_id === null)[0];
        // const newCategories = this.models.map(({
        //   id,
        //   title: name,
        // }) => ({
        //   id,
        //   name
        // }));
        // newCategories.unshift({name: 'All'});
        this.modelOptions = res.data.payload.filter(item => item.parent_id === null);
        this.activeModels = [];

      })
      .catch(() => {
         
      })
    },
    getPacks() {
      this.$http.get(process.env.VUE_APP_API + 'packs')
      .then((res) => {
        this.packs = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    // setAllModels() {
    //   const newCategories = this.models.map(({
    //     id,
    //     title: name,
    //   }) => ({
    //     id,
    //     name
    //   }));
    //   this.setActiveModel(newCategories);
    // },
    openProducts(toProductList) {
      let self = this;
      let newActiveModels;
      if (!Array.isArray(self.activeModels) ) {
        newActiveModels = self.activeModels.id
      } else {
        newActiveModels = self.activeModels.map(obj => obj.id);
      }
      let modelIdApi = newActiveModels;

      let newActiveCategories;
      if (!Array.isArray(self.activeCategories) ) {
        newActiveCategories = self.activeCategories.id
      } else {
        newActiveCategories = self.activeCategories.map(obj => obj.id);
      }
      let categoryIdApi = '&category_id=' + newActiveCategories;
      let sortApi = '&sort=' + self.sort
      
      this.$http.get(process.env.VUE_APP_API + 'products/catalog?model_id=' + modelIdApi + categoryIdApi + sortApi )
      .then((res) => {
          self.productList = res.data.payload;
          if (self.$route.name != 'ProductList' &&  toProductList) {
            self.$router.push({path: '/product-list'});
          }
      })
      .catch(() => {
        
      })
    },
    getCurrencies() {
      return this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
          const that = this;
          
          this.currencyOptions = res.data.payload;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency");

            const currency = res.data.payload.find(({code}) => code === that.currencyCode);

            if (currency) {
              this.currencyValue = currency.value;
              this.currency = currency.symbol;
            }
          } else {
            this.currencyCode = this.currencyOptions[0].code;
            this.currency = this.currencyOptions[0].symbol;
            this.currencyValue = this.currencyOptions[0].value;
          }
      })
      .catch(() => {
       
      })
    },
    setSort(sort) {
      this.sort = sort;
      this.openProducts();
    },
    setActiveModel(models) {

      this.activeModels = models;
     
      let activeCategories = this.categoryCreateOptions.filter(item => item.parent_id === models.id);
      this.activeCategories = activeCategories;

      this.openProducts(true);
    },
    setActiveCategory(categories) {
      this.activeCategories = categories;
      this.openProducts();
    },
    filterObjectsWithSameId(arr1, arr2) {
      const resultArray = arr1.filter(obj1 =>
        arr2.some(obj2 => obj2.parent_id === obj1.id)
      );
      return resultArray;
    },
    filterObjectsWithSameId2(arr1, arr2) {
      const resultArray = arr1.filter(obj1 =>
        arr2.some(obj2 => obj2.id === obj1.parent_id)
      );
      return resultArray;
    },
    isElementVisible(element) {
      const computedStyle = window.getComputedStyle(element);
      const isVisible = 
        computedStyle.display !== 'none' &&
        computedStyle.visibility !== 'hidden' &&
        parseInt(computedStyle.width, 10) > 0 &&
        parseInt(computedStyle.height, 10) > 0;

      return isVisible;
    },
    editPrompt(item) {
      this.editData = item;
      this.$router.push({path: '/promt-create/' + item.id});
    },
    clearEditData() {
      this.editData = null;
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      const that = this;
      const currency = this.currencyOptions.find(({code}) => code === that.currencyCode);

      if (currency) {
       
        this.currency = currency.symbol;
        this.currencyValue = currency.value;
        console.log(currency.symbol)
        if (this.isAuth) {
          this.getDepositHistory();
          this.getCartContents();
          this.getProfileData();
          this.getPromptHistory();
          this.getOrderHistory();
          this.getCreatedHistory();
        }
        this.getPacks();
        this.getActiveModelProducts(this.modelOptions[0].id);
        this.openProducts();
      }
    },
    getSettings() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        this.settings = res.data.payload;
        const snippet = res.data.payload.google_tag;

        if (snippet) {
          loadScriptsFromString(snippet);
        }
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
    },
  },
  mounted: function() {
    this.currency = process.env.VUE_APP_CURRENCY;
    this.hostname = this.getDomainName(window.location.hostname)
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    this.getCurrencies();
    this.getSettings();

    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getPromptHistory();
          self.getOrderHistory();
          self.getDepositHistory();
          self.getCreatedHistory();
          self.getProfileData();
          self.getCartContents();
        }
    })
    .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
    })

    
    this.getTextPages();
    this.getModels();
    this.getCategories();
    this.getStableDiffusion();
    this.getDalle2();
    this.getChatGpt();
    this.getMidjourney();
    this.getPacks();
  },
  computed: {
    
  }
}
</script>
